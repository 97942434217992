<script setup lang="ts">import { computed as _computed, toRef as _toRef } from 'vue';
import { createPropsRestProxy } from '/vue2-reactivity-transform-helper';
const props = createPropsRestProxy(__props, ["account","command","context"]);

import type { mastodon } from 'masto'

defineProps({
  "account": null,
  "relationship": null,
  "context": null,
  "command": { type: Boolean,  }
})

const { t } = useI18n()
const isSelf = (useSelfAccount(() => __props.account))
const enable = _computed(() => !isSelf.value && currentUser.value)
const relationship = _computed(() => props.relationship || useRelationship(__props.account).value)

const __$temp_1 = (useMasto()),
  client = _toRef(__$temp_1, 'client');
async function toggleFollow() {
  if (relationship.value!.following) {
    if (await openConfirmDialog({
      title: t('confirm.unfollow.title'),
      confirm: t('confirm.unfollow.confirm'),
      cancel: t('confirm.unfollow.cancel'),
    }) !== 'confirm')
      return
  }
  relationship.value!.following = !relationship.value!.following
  try {
    const newRel = await client.value.v1.accounts[relationship.value!.following ? 'follow' : 'unfollow'](__props.account.id)
    Object.assign(relationship.value!, newRel)
  }
  catch (err) {
    console.error(err)
    // TODO error handling
    relationship.value!.following = !relationship.value!.following
  }
}

async function unblock() {
  relationship.value!.blocking = false
  try {
    const newRel = await client.value.v1.accounts.unblock(__props.account.id)
    Object.assign(relationship.value!, newRel)
  }
  catch (err) {
    console.error(err)
    // TODO error handling
    relationship.value!.blocking = true
  }
}

async function unmute() {
  relationship.value!.muting = false
  try {
    const newRel = await client.value.v1.accounts.unmute(__props.account.id)
    Object.assign(relationship.value!, newRel)
  }
  catch (err) {
    console.error(err)
    // TODO error handling
    relationship.value!.muting = true
  }
}

useCommand({
  scope: 'Actions',
  order: -2,
  visible: () => __props.command && enable.value,
  name: () => `${relationship.value?.following ? t('account.unfollow') : t('account.follow')} ${getShortHandle(__props.account)}`,
  icon: 'i-ri:star-line',
  onActivate: () => toggleFollow(),
})

const buttonStyle = _computed(() => {
  if (relationship.value?.blocking)
    return 'text-inverted bg-red border-red'

  if (relationship.value?.muting)
    return 'text-base bg-card border-base'

  // If following, use a label style with a strong border for Mutuals
  if (relationship.value ? relationship.value.following : __props.context === 'following')
    return `text-base ${relationship.value?.followedBy ? 'border-strong' : 'border-base'}`

  // If not following, use a button style
  return 'text-inverted bg-primary border-primary'
})
</script>

<template>
  <button
    v-if="enable"
    gap-1 items-center group
    :disabled="relationship?.requested"
    border-1
    rounded-full flex="~ gap2 center" font-500 min-w-30 h-fit px3 py1
    :class="buttonStyle"
    :hover="!relationship?.blocking && !relationship?.muting && relationship?.following ? 'border-red text-red' : 'bg-base border-primary text-primary'"
    @click="relationship?.blocking ? unblock() : relationship?.muting ? unmute() : toggleFollow()"
  >
    <template v-if="relationship?.blocking">
      <span group-hover="hidden">{{ $t('account.blocking') }}</span>
      <span hidden group-hover="inline">{{ $t('account.unblock') }}</span>
    </template>
    <template v-if="relationship?.muting">
      <span group-hover="hidden">{{ $t('account.muting') }}</span>
      <span hidden group-hover="inline">{{ $t('account.unmute') }}</span>
    </template>
    <template v-else-if="relationship ? relationship.following : context === 'following'">
      <span group-hover="hidden">{{ relationship?.followedBy ? $t('account.mutuals') : $t('account.following') }}</span>
      <span hidden group-hover="inline">{{ $t('account.unfollow') }}</span>
    </template>
    <template v-else-if="relationship?.requested">
      <span>{{ $t('account.follow_requested') }}</span>
    </template>
    <template v-else-if="relationship ? relationship.followedBy : context === 'followedBy'">
      <span group-hover="hidden">{{ $t('account.follows_you') }}</span>
      <span hidden group-hover="inline">{{ $t('account.follow_back') }}</span>
    </template>
    <template v-else>
      <span>{{ account.locked ? $t('account.request_follow') : $t('account.follow') }}</span>
    </template>
  </button>
</template>
