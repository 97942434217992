<script setup lang="ts">import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "account": null
})

const serverName = _computed(() => getServerName(__props.account))
</script>

<template>
  <p line-clamp-1 whitespace-pre-wrap break-all text-secondary-light leading-tight dir="ltr">
    
    <span text-secondary>{{ getShortHandle(account) }}</span>
    <span v-if="serverName" text-secondary-light>@{{ serverName }}</span>
  </p>
</template>
